import {
  type LoaderData,
  useLoaderData,
  useNavigation,
  type LoaderFunction,
  wordpressLoader,
  json,
  redirect,
  WpController
} from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import progress from 'nprogress'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'
import type { RedirectionItemType } from '~/graphql/types'

export const loader: LoaderFunction = async (data) => {
  const url = new URL(data.request.url)

  try {
    const page = await wordpressLoader(data)

    return json(page.body, page.headers)
  } catch (error) {
    const wpController = new WpController()
    await wpController.init()

    const query = await wpController.query(`
      query Redirects {
        Redirects: redirections {
          target
          url
          code
        }
      }
    `)

    const redirection = query?.Redirects?.find(
      (_redirect: RedirectionItemType) => removeLeadingAndTrailingSlashes(_redirect.url) === removeLeadingAndTrailingSlashes(url.pathname)
    )

    if (redirection) {
      return redirect(redirection.target, redirection.code)
    }

    throw new Response(null, {
      status: 404
    })
  }
}

function removeLeadingAndTrailingSlashes(str: string) {
  return str.replace(/^\/+|\/+$/g, '')
}

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  const transition = useNavigation()

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      progress.start()
    } else {
      progress.done()
    }
  }, [transition.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex as any} />
    </Layout>
  )
}
